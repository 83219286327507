/* eslint-disable react/no-danger */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable func-names */
/* eslint-disable radix */
/* eslint-disable max-len */
import React from 'react';
import App from 'next/app';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '@theme_theme';
import customTheme from '@theme/theme-custom';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { appWithTranslation } from '@i18n';
import {
    features, sentry, checkoutKeyCookies,
    markerIo,
} from '@config';
import {
    getLoginInfo, getAuthInfo, getLastPathWithoutLogin, getLoginInfoDashboard,
} from '@helper_auth';
import { setResolver, testLocalStorage } from '@helper_localstorage';
import { RewriteFrames } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { unregister } from 'next-offline/runtime';

import PageProgressLoader from '@common_loaders/PageProgress';
import getNextConfig from 'next/config';
import Notification from '@lib_firebase/notification';
import firebase from '@lib_firebase/index';
import routeMiddleware from '@middleware_route';

import * as Sentry from '@sentry/node';
import ModalCookies from '@core_modules/theme/components/modalCookies';
import { persistor, store } from '@core/redux/store';
import withRedux from 'next-redux-wrapper';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryComponent from '@core_modules/commons/ErrorBoundary';
import MarkerComponent from '@core/modules/commons/MarkerIoClient';
import { RED_VIOLET } from '@theme_color';

const { publicRuntimeConfig } = getNextConfig();

/*
 * ---------------------------------------------
 * SENTRY INITIALIZATION
 */
if (sentry.enabled && typeof publicRuntimeConfig !== 'undefined' && sentry.dsn[publicRuntimeConfig.appEnv]) {
    const distDir = `${publicRuntimeConfig.rootDir}/.next`;
    Sentry.init({
        enabled: process.env.NODE_ENV === sentry.enableMode,
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    // eslint-disable-next-line no-param-reassign
                    frame.filename = frame.filename.replace(distDir, 'app:///_next');
                    return frame;
                },
            }),
            new Integrations.BrowserTracing(),
        ],
        environment: publicRuntimeConfig.appEnv,
        dsn: sentry.dsn[publicRuntimeConfig.appEnv],
        tracesSampleRate: 0.5,
    });
}

class MyApp extends App {
    constructor(props) {
        super(props);
        this.isLogin = false;
    }

    static async getInitialProps({ Component, ctx }) {
        let pageProps = {};

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }
        const {
            res, pathname, query, req,
        } = ctx;

        /*
         * ---------------------------------------------
         * MAINTAIN LOGIN FLAG
         * check if login from server
         */
        let isLogin = 0;
        let isAuth = 0;
        let isLoginDashboard = 0;
        let lastPathNoAuth = '';
        const allcookie = req ? req.cookies : {};
        if (typeof window !== 'undefined') {
            isLogin = getLoginInfo();
            isLoginDashboard = getLoginInfoDashboard();
            isAuth = getAuthInfo();
            lastPathNoAuth = getLastPathWithoutLogin();
        } else {
            isLogin = allcookie[checkoutKeyCookies.flagLogin] || 0;
            isLoginDashboard = allcookie[checkoutKeyCookies.flagLoginDashboard] || 0;
            isAuth = allcookie[checkoutKeyCookies.flagAuthorization] || 0;
            lastPathNoAuth = req.session && typeof req.session !== 'undefined' && req.session.lastPathNoAuth && typeof req.session.lastPathNoAuth !== 'undefined'
                ? req.session.lastPathNoAuth
                : '/';
        }
        isLogin = parseInt(isLogin);
        isAuth = parseInt(isAuth);

        /*
         * ---------------------------------------------
         * [COOKIES] OTHER
         */
        const app_cookies = { cookies_currency: req ? req.cookies.app_currency : null };

        /*
         * ---------------------------------------------
         * CALLING ROUTING MIDDLEWARE
         */
        routeMiddleware({
            res,
            req,
            query,
            pathname,
            isLogin: publicRuntimeConfig.mode === '' || publicRuntimeConfig.mode === 'dashboard' ? isLoginDashboard : isLogin,
            lastPathNoAuth,
        });

        /*
         * ---------------------------------------------
         * GET CONFIGURATIONS FROM COOKIES
         * TO BE PROVIDED INTO PAGE PROPS
         */

        /*
         * ---------------------------------------------
         * RETURNS
         */
        let token;
        if (req && req.session && req.session.token) {
            token = req.session.token;
        }
        return {
            pageProps: {
                ...pageProps,
                app_cookies,
                isLogin,
                isAuth,
                token,
            },
        };
    }

    componentDidMount() {
        /*
         * ---------------------------------------------
         * ADDING CUSTOM SERVICE WORKER
         */
        if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production' && typeof document !== 'undefined') {
            if (document.readyState === 'complete') {
                this.registerServiceWorker();
            } else {
                window.addEventListener('load', () => {
                    this.registerServiceWorker();
                });
            }
        }

        /*
         * ---------------------------------------------
         * FIREBASE INITIALIZATION
         */
        if (features.firebase.pushNotification.enabled) {
            // initial firebase messaging
            Notification.init();
            // handle if have message on focus
            try {
                const messaging = firebase.messaging();
                // Handle incoming messages. Called when:
                // - a message is received while the app has focus
                // - the user clicks on an app notification created by a service worker
                //   `messaging.setBackgroundMessageHandler` handler.
                messaging.onMessage((payload) => {
                    navigator.serviceWorker.ready.then((registration) => {
                        // This prevents to show one notification for each tab
                        setTimeout(() => {
                            console.log('[firebase-messaging-sw.js] Received foreground message ', payload);
                            const lastNotification = localStorage.getItem('lastNotification');
                            const isDifferentContent = payload.data.updated_date !== lastNotification;
                            if (isDifferentContent) {
                                localStorage.setItem('lastNotification', payload.data.updated_date + payload.data.title);
                                registration.showNotification(payload.data.title, {
                                    body: payload.data.body,
                                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                                    icon: payload.data.icons || '',
                                    image: payload.data.image || '',
                                    requireInteraction: true,
                                    data: payload.data,
                                });
                            }
                        }, Math.random() * 1000);
                    });
                });
            } catch (err) {
                console.log(err);
            }
        }

        /*
         * LAZY LOADING FONTS
         * Use this to load non critical fonts
         */
        // Fonts();

        /*
         * ---------------------------------------------
         * REMOVE THE SERVER SIDE INJECTED CSS
         * This is for speed performanc purpose
         */
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        /*
         * ---------------------------------------------
         * COOKIE CLEARANCE
         * remove config cookie if the page is reloaded
         */
        if (typeof window !== 'undefined') {
            window.onbeforeunload = function () {
                setResolver({});
            };
        }
    }

    componentWillUnmount() {
        unregister();
    }

    registerServiceWorker() {
        navigator.serviceWorker.register('/service-worker.js').then(
            (registration) => {
                console.log('Service Worker registration successful with scope: ', registration.scope);
            },
            (err) => {
                console.log('Service Worker registration failed: ', err);
            },
        );
    }

    createCustomTheme() {
        return createMuiTheme;
    }

    render() {
        const { Component, pageProps } = this.props;
        /*
        Theme configurations based on mode used, if in `checkout` mode and the `primaryColor` prop is provided,
        use custom theme. Also provided global classes using styled-jsx syntax,
        for some components that are not available on the Material-UI theme by default.
        */
        const themeColor = typeof window !== 'undefined'
            ? localStorage.getItem('themeColor')
            : RED_VIOLET;
        const layoutTheme = publicRuntimeConfig.mode !== 'dashboard' && themeColor
            ? customTheme({ primaryColor: themeColor })
            : theme;

        if (typeof window !== 'undefined' && testLocalStorage() === false) {
            // not available
            return (
                <ThemeProvider theme={layoutTheme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <ModalCookies {...pageProps} />
                </ThemeProvider>
            );
        }

        return (
            <>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={layoutTheme}>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <PageProgressLoader />
                        {
                            markerIo.enable && <MarkerComponent />
                        }

                        <ErrorBoundary fallbackRender={ErrorBoundaryComponent}>
                            <Component {...pageProps} />

                        </ErrorBoundary>
                    </ThemeProvider>
                </PersistGate>
            </>
        );
    }
}

const makeStore = () => store;
export default withRedux(makeStore)(appWithTranslation(MyApp));
