/* eslint-disable react/no-unused-state */
import React from 'react';
import Button from '@common_button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@common_typography';
import { CodeBlock, dracula } from 'react-code-blocks';
import markerSDK from '@marker.io/browser';
import { red } from '@material-ui/core/colors';
import { markerIo } from '@config';
import getNextConfig from 'next/config';
import { useSelector } from 'react-redux';

const { publicRuntimeConfig } = getNextConfig();

function ErrorBoundary({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    const checkout = useSelector((state) => state.checkout);

    const handleReport = async () => {
        const widget = await markerSDK.loadWidget({
            project: markerIo.projectId[publicRuntimeConfig.appEnv],
            customData: {
                cart: checkout.cart,
                isGuest: checkout.isGuest,
                emailGuest: '',
                shippingAddress: checkout.shippingAddress,
                billingAddress: checkout.billingAddress,
                shippingMethods: checkout.shippingMethods,
                selectedShippingMethods: checkout.selectedShippingMethods,
                selectedPaymentMethods: checkout.selectedPaymentMethods,
                deliveryMethod: checkout.deliveryMethod,
                promoCode: checkout.promoCode,
                giftCartUsed: checkout.giftCartUsed,
                appliedExtraFee: checkout.appliedExtraFee,
                rewardPoint: checkout.rewardPoint,
                appliedRewardPoint: checkout.appliedRewardPoint,
                storeCredit: checkout.storeCredit,
                appliedStoreCredit: checkout.appliedStoreCredit,
                selectedStore: checkout.selectedStore,
                pickupInformation: checkout.pickupInformation,
                member: checkout.member,
                swiftPromotions: checkout.swiftPromotions,
            },
        });

        if (widget?.capture) {
            widget.capture();
        }
    };

    return (
        <Container
            style={{
                textAlign: 'center',
                paddingTop: '50px',
                background: '#632b87',
                height: '100vh',
            }}
            maxWidth="xl"
        >
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <img
                    alt="logoImg"
                    src="/assets/img/scv2_logo_white.svg"
                    className="img-logo"
                />

                <Typography variant="h1" type="bold" color="white">
                    Something went wrong!
                </Typography>

                <Typography color="white" size="14" align="center" style={{ maxWidth: 600, padding: '20px' }}>
                    Oh no! This is not supposed to happen.
                    Please check your internet connection or you can refresh the page once.
                    If the issue is still not yet resolved then, feel free to reach out our customer support.
                </Typography>

                <Box
                    marginTop={2}
                    marginBottom={5}
                    style={{
                        width: '100%',
                        maxWidth: 600,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    {error.message ? (
                        <>
                            <Typography color="white" align="left">
                                Detail Error
                            </Typography>
                            <CodeBlock text={error} language="react" showLineNumbers={false} theme={dracula} />
                        </>
                    ) : null}
                </Box>

                <Box justifyContent="center" display="flex" flexDirection="row" gridGap={15}>
                    <Button className="btn-reset btn-white" variant="contained" onClick={resetErrorBoundary}>
                        Try Again
                    </Button>
                    <Button className="btn-reset btn-red" variant="contained" onClick={handleReport}>
                        Report
                    </Button>
                </Box>
            </Box>
            <style jsx global>
                {`
                    .img-logo {
                        width: 200px;
                        margin-bottom: 50px;
                    }

                    span, p {
                        margin: 0;
                    }

                    .btn-reset {
                        width: 200px;
                        font-weight: 600;
                    }

                    .btn-white {
                        background: #fff !important;
                        color: #000;
                    }

                    .btn-red {
                        background: ${red[500]} !important;
                        color: #FFF;
                    }

                    @media only screen and (max-width: 600px) {
                        .img-logo {
                            max-width: 100px;
                        }
                    }
                `}
            </style>
        </Container>
    );
}

export default ErrorBoundary;
