/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const defaultSwiftPromoVar = {
    freeGifts: {
        ruleId: '',
        skus: [],
        selectedItems: [],
        initCartItems: [],
        maxQty: 0,
        totalItems: 0,
        type: '',
    },
};

const defaultLoading = {
    all: true,
    promo: false,
    loadCartItem: false,
    loadSummary: false,
    loadGiftCart: false,
    loadRewardPoint: false,
    loadStoreCredit: false,
    loadPrice: false,
    loadItem: false,
};

const defaultError = {
    email: false,
    shippingAddress: false,
    billingAddress: false,
    shippingMethod: false,
    paymentMethod: false,
    pickupInformation: false,
};

export const initialState = {
    isGuest: false,
    emailGuest: '',
    addresses: [],
    shippingAddress: null,
    billingAddress: null,
    shippingMethods: null,
    shippingInsurance: {
        enable: false,
        insurance: null,
    },
    selectedShippingMethods: null,
    selectedPaymentMethods: null,
    deliveryMethod: 'home',
    promoCode: null,
    giftCartUsed: [],
    gifCartList: [],
    appliedExtraFee: null,
    rewardPoint: null,
    appliedRewardPoint: null,
    storeCredit: null,
    appliedStoreCredit: null,
    selectedStore: null,
    pickupInformation: null,
    isVirtual: false,
    loading: defaultLoading,
    error: defaultError,
    cart: {},
    member: null,
    swiftPromoList: [],
    subscription: {
        hasSet: false,
        data: {},
    },
    swiftPromotions: defaultSwiftPromoVar,
    ewalletPoints: 'REDEEM_NONE',
};

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        resetCheckout(state) {
            state.isGuest = false;
            state.emailGuest = '';
            state.addresses = [];
            state.shippingAddress = null;
            state.billingAddress = null;
            state.shippingMethods = null;
            state.selectedShippingMethods = null;
            state.selectedPaymentMethods = null;
            state.deliveryMethod = 'home';
            state.promoCode = null;
            state.giftCartUsed = [];
            state.gifCartList = [];
            state.appliedExtraFee = null;
            state.rewardPoint = null;
            state.appliedRewardPoint = null;
            state.storeCredit = null;
            state.appliedStoreCredit = null;
            state.selectedStore = null;
            state.pickupInformation = null;
            state.isVirtual = false;
            state.loading = defaultLoading;
            state.error = defaultError;
            state.cart = {};
            state.member = null;
            state.swiftPromoList = [];
            state.subscription = {
                hasSet: false,
                data: {},
            };
            state.swiftPromotions = defaultSwiftPromoVar;
            state.ewalletPoint = 'REDEEM_NONE';
            state.shippingInsurance = {
                enable: true,
                insurance: null,
            };
        },
        setGuest(state, action) {
            state.isGuest = action.payload;
        },
        setMember(state, action) {
            state.member = {
                ...state.member,
                ...action.payload,
            };
        },
        setIsVirtual(state, action) {
            state.isVirtual = action.payload;
        },
        setLoading(state, action) {
            state.loading = {
                ...state.loading,
                ...action.payload,
            };
        },
        setError(state, action) {
            state.error = {
                ...state.error,
                ...action.payload,
            };
        },
        setCart(state, action) {
            state.cart = {
                ...state.cart,
                ...action.payload,
            };
        },
        setPromoCode(state, action) {
            state.promoCode = action.payload;
        },
        setAppliedRewardPoint(state, action) {
            state.appliedRewardPoint = action.payload;
        },
        setAppliedStoreCredit(state, action) {
            state.appliedStoreCredit = action.payload;
        },
        setGiftCartUsed(state, action) {
            state.giftCartUsed = action.payload;
        },
        setSelectedShippingMethods(state, action) {
            state.selectedShippingMethods = action.payload;
        },
        setSelectedPaymentMethods(state, action) {
            state.selectedPaymentMethods = action.payload;
        },
        setGiftCartList(state, action) {
            state.gifCartList = action.payload;
        },
        setRewardPoint(state, action) {
            state.rewardPoint = action.payload;
        },
        setStoreCredit(state, action) {
            state.storeCredit = action.payload;
        },
        setSwiftPromotions(state, action) {
            state.swiftPromotions = action.payload;
        },
        setAddresses(state, action) {
            state.addresses = action.payload;
        },
        setShippingAddress(state, action) {
            state.shippingAddress = action.payload;
        },
        setBillingAddress(state, action) {
            state.billingAddress = action.payload;
        },
        setSelectedStore(state, action) {
            state.selectedStore = action.payload;
        },
        setPickupInformation(state, action) {
            state.pickupInformation = action.payload;
        },
        setDeliveryMethod(state, action) {
            state.deliveryMethod = action.payload;
        },
        setEwalletPoints(state, action) {
            state.ewalletPoints = action.payload;
        },
        setSwiftPromoList(state, action) {
            state.swiftPromoList = action.payload;
        },
        setShippingMethod(state, action) {
            state.shippingMethods = action.payload;
        },
        setSubscription(state, action) {
            state.subscription = action.payload;
        },
        setEmailGuest(state, action) {
            state.emailGuest = action.payload;
        },
        enableShippingInsurance(state, action) {
            state.shippingInsurance = {
                ...state.shippingInsurance,
                enable: action.payload,
            };
        },
        setShippingInsurance(state, action) {
            state.shippingInsurance = {
                ...state.shippingInsurance,
                insurance: action.payload,
            };
        },
    },
});

export const {
    setGuest,
    setMember,
    setIsVirtual,
    setLoading,
    setError,
    setCart,
    setPromoCode,
    setAppliedRewardPoint,
    setAppliedStoreCredit,
    setGiftCartUsed,
    setSelectedPaymentMethods,
    setSelectedShippingMethods,
    setGiftCartList,
    setRewardPoint,
    setStoreCredit,
    setSwiftPromotions,
    setAddresses,
    setShippingAddress,
    setBillingAddress,
    setSelectedStore,
    setDeliveryMethod,
    setPickupInformation,
    setEwalletPoints,
    setSwiftPromoList,
    setShippingMethod,
    setSubscription,
    setEmailGuest,
    resetCheckout,
    enableShippingInsurance,
    setShippingInsurance,
} = checkoutSlice.actions;

const checkouteReducer = checkoutSlice.reducer;
export default checkouteReducer;
