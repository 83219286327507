import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
    persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,
} from 'redux-persist';
import checkoutReducer from './reducers/checkout';
import authReducer from './reducers/auth';
import { getAppEnv } from '../helpers/env';

const appEnv = getAppEnv();

const persistConfig = {
    key: 'state-scv2',
    storage,
    whitelist: ['checkout', 'auth'],
};

const rootReducer = combineReducers({
    checkout: checkoutReducer,
    auth: authReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
    devTools: appEnv === 'local',
});

const persistor = persistStore(store);
export { store, persistor };
