'use client';

import { useEffect } from 'react';
import markerSDK from '@marker.io/browser';
import getNextConfig from 'next/config';
import { markerIo } from '@config';
import { useSelector } from 'react-redux';

const { publicRuntimeConfig } = getNextConfig();

export default function MarkerComponent() {
    const checkout = useSelector((state) => state.checkout);

    useEffect(() => {
        markerSDK.loadWidget({
            project: markerIo.projectId[publicRuntimeConfig.appEnv],
            customData: {
                cart: checkout.cart,
                isGuest: checkout.isGuest,
                emailGuest: '',
                shippingAddress: checkout.shippingAddress,
                billingAddress: checkout.billingAddress,
                shippingMethods: checkout.shippingMethods,
                selectedShippingMethods: checkout.selectedShippingMethods,
                selectedPaymentMethods: checkout.selectedPaymentMethods,
                deliveryMethod: checkout.deliveryMethod,
                promoCode: checkout.promoCode,
                giftCartUsed: checkout.giftCartUsed,
                appliedExtraFee: checkout.appliedExtraFee,
                rewardPoint: checkout.rewardPoint,
                appliedRewardPoint: checkout.appliedRewardPoint,
                storeCredit: checkout.storeCredit,
                appliedStoreCredit: checkout.appliedStoreCredit,
                selectedStore: checkout.selectedStore,
                pickupInformation: checkout.pickupInformation,
                member: checkout.member,
                swiftPromotions: checkout.swiftPromotions,
            },
        });
    }, []);

    return null;
}
